#news{
  background-color: #f2f2f2;
  .inner-wrap {
    border-top: solid 10px #000;
  }
  .inner{
    max-width: 1000px;
    margin: auto;
    @include PC {
      width: 95%;
      padding: 90px 0;
    }
    @include SP {
      width: calc(100% - 40px);
      padding: 45px 0;
    }
  }
  .box{
    text-align: left;
  }
  .heading03{
    font-size: 1.2rem;
    padding-bottom: 16px;
    background-color: #f2f2f2;
    text-align: left;
  }
  .inner-wrap__box{
    @include PC {
      display: flex;
      justify-content: space-between;
    }
  }
  .inner-wrap__left{
    @include PC {width: 78%;}
    .box{
      margin-top: 20px;
      &:first-child{
        margin-top: 0;
      }
    }
  }
  .inner-wrap__right{
    background-color: #fff;
    @include PC {width: 20%;}
    @include SP {margin-top: 30px;}
  }
  .group{
    background-color: #fff;
    margin-bottom: 6px;
    &--link{
      display: block;
      padding: 20px;
      &:hover{color: #00789c;}
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  .tag{
    background-color: #00789b;
    color: #fff;
    font-size: 10px;
    @include PC {padding: 6px;}
    @include SP {padding: 4px;}
  }
  .date{
    line-height: 22px;
    display: inline-block;
    @include PC {
      font-size: 14px;
      margin-left: 20px;
    }
    @include SP {
      font-size: 12px;
      margin-left: 10px;
    }
  }
  .name{
    line-height: 22px;
    @include PC {
      font-size: 14px;
      margin-left: 20px;
      display: inline-block;
    }
    @include SP {
      font-size: 12px;
      display: block;
      margin-top: 5px;
    }
  }
  .article{
    margin-top: 16px;
    @include PC {font-size: 14px;}
    @include SP {font-size: 1.3rem;}
  }
  .item{
    font-size: 14px;
    letter-spacing: .1em;
    margin: 0 20px;
    a{
      padding: 20px 0;
      display: block;
      border-bottom: solid 1px #000;
      position: relative;
      @include PC {padding: 20px 0;}
      @include SP {padding: 16px 0;}
      &:hover{color: #00789c;}
      &::after{
        content: "";
        width: 10px;
        height: 10px;
        display:  block;
        border-top: solid 1px;
        border-right: solid 1px;
        transform: rotate(45deg);
        position:  absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
    &:last-child{
      a{
        border: none;
      }
    }
  }
}
