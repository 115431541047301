@charset "UTF-8";

html{
  width: 100%;
  height:100%;
  font-size: 62.5%;/* 16px x 0.625 = 10px(=1rem) */
}
body {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo",sans-serif;
  font-style: normal;
  font-weight: 100;
  -webkit-text-size-adjust: none;
  width: 100%;
  height: 100%;
  position: relative;
  color: #222;

  @include PC {
    // min-width: 960px;
    font-size: 1.5rem;/* 16px*/
    line-height: 1.5;
  }
  @include SP {
    font-size: 1.6rem;
  }
  img{
    width: 100%;
    vertical-align: bottom;
  }
  a{
    color: #000;
    transition: color 0.1s ease-in-out;
  }
}
//common
.pc{
  @include PC {display: block;}
  @include SP {display: none !important;}
}
.sp{
  @include PC {display: none !important;}
  @include SP {display: block;}
}
.arrowDown{
  transition: transform 300ms linear;
  // transform-origin: center;
  &:after{
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    left: 2px;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(135deg);
    display: block;

  }
  &.on{
    transform: rotate(-180deg);
  }
}
.en{
  // font-family: 'Oswald', sans-serif;
  letter-spacing: 0.14em;
  font-family: aktiv-grotesk,sans-serif;
  font-weight: 400;
  font-style: normal;
}
.content{
  @include PC {padding-top: 110px;}
  @include SP {padding-top: 110px;}
}
.loading{
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
// .kozu-B{
//   font-family: kozuka-gothic-pro, sans-serif;
//   font-weight: 700;
//   font-style: normal;
// }
// .kozu-R{
//   font-family: kozuka-gothic-pro, sans-serif;
//   font-weight: 400;
//   font-style: normal;
// }
