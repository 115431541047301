@charset "UTF-8";
/* IEハック用mixin */
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  background-repeat: no-repeat;
  font-weight: normal; }

article, aside, dialog, figure, footer, header,
hgroup, nav, section {
  display: block; }

ul, li {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select, textarea {
  vertical-align: middle;
  border-radius: 0;
  appearance: none;
  background-color: transparent;
  border: none;
  color: #fff; }

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
  background-color: none !important;
  color: #fff !important; }

:focus {
  outline: none; }

a, button {
  outline: none;
  border: none;
  cursor: pointer; }

a:hover, button:hover {
  outline: none; }

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  list-style: none;
  background-repeat: no-repeat;
  box-sizing: border-box;
  vertical-align: inherit;
  vertical-align: bottom; }

em, i {
  font-style: normal; }

svg {
  height: auto; }

select::-ms-expand {
  display: none; }

.selector::-webkit-scrollbar {
  display: none; }

html {
  width: 100%;
  height: 100%;
  font-size: 62.5%;
  /* 16px x 0.625 = 10px(=1rem) */ }

body {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo",sans-serif;
  font-style: normal;
  font-weight: 100;
  -webkit-text-size-adjust: none;
  width: 100%;
  height: 100%;
  position: relative;
  color: #222; }
  @media (min-width: 769px) {
    body {
      font-size: 1.5rem;
      /* 16px*/
      line-height: 1.5; } }
  @media (max-width: 768px) {
    body {
      font-size: 1.6rem; } }
  body img {
    width: 100%;
    vertical-align: bottom; }
  body a {
    color: #000;
    transition: color 0.1s ease-in-out; }

@media (min-width: 769px) {
  .pc {
    display: block; } }

@media (max-width: 768px) {
  .pc {
    display: none !important; } }

@media (min-width: 769px) {
  .sp {
    display: none !important; } }

@media (max-width: 768px) {
  .sp {
    display: block; } }

.arrowDown {
  transition: transform 300ms linear; }
  .arrowDown:after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    left: 2px;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    transform: rotate(135deg);
    display: block; }
  .arrowDown.on {
    transform: rotate(-180deg); }

.en {
  letter-spacing: 0.14em;
  font-family: aktiv-grotesk,sans-serif;
  font-weight: 400;
  font-style: normal; }

@media (min-width: 769px) {
  .content {
    padding-top: 110px; } }

@media (max-width: 768px) {
  .content {
    padding-top: 110px; } }

.loading {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: #fff; }

footer {
  color: #000;
  border-top: solid 2px; }

.f-nav {
  position: relative; }
  @media (min-width: 769px) {
    .f-nav {
      padding: 30px 3.33% 30px;
      display: flex;
      align-items: end;
      justify-content: space-between; } }
  @media (max-width: 768px) {
    .f-nav {
      padding: 30px 20px 50px; } }
  @media (min-width: 769px) {
    .f-nav_wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap; } }
  @media screen and (min-width: 769px) and (max-width: 1300px) {
    .f-nav_wrap {
      display: block; } }
  .f-nav .pageslist_item a {
    color: #000; }
  @media (min-width: 769px) {
    .f-nav .pageslist {
      margin-right: 50px;
      flex-wrap: wrap; } }
  @media screen and (min-width: 769px) and (max-width: 1300px) {
    .f-nav .pageslist {
      margin-right: 20px; } }
  @media (max-width: 768px) {
    .f-nav .pageslist {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      margin-bottom: 5px; } }
  @media (min-width: 769px) {
    .f-nav .pageslist_item {
      font-size: 1.4rem; } }
  @media screen and (min-width: 769px) and (max-width: 1300px) {
    .f-nav .pageslist_item {
      margin-right: 4%; } }
  @media (max-width: 768px) {
    .f-nav .pageslist_item {
      font-size: 1.3rem;
      margin-bottom: 5px; } }

.f-copy {
  letter-spacing: .1em; }
  @media (min-width: 769px) {
    .f-copy {
      font-size: 1rem;
      white-space: nowrap;
      margin-top: 30px; } }
  @media (max-width: 768px) {
    .f-copy {
      font-size: 1rem;
      padding-top: 10px;
      text-align: center; } }

@media (min-width: 769px) {
  .g-header {
    width: 100%;
    padding: 28px 0;
    position: fixed;
    z-index: 9999;
    background-color: #fff; } }

@media (max-width: 768px) {
  .g-header {
    width: 100%;
    padding: 25px 0;
    position: fixed;
    z-index: 9999;
    background-color: #fff;
    border-bottom: solid 1px #000; } }

@media (min-width: 769px) {
  .g-logo {
    width: 130px; } }

@media (max-width: 768px) {
  .g-logo {
    width: 120px;
    margin: auto; } }

@media (min-width: 769px) {
  .g-wrap {
    padding: 0 3.33%;
    display: flex;
    align-items: center;
    justify-content: space-between; } }

@media (min-width: 769px) {
  .pageslist {
    display: flex; } }

@media (max-width: 768px) {
  .pageslist {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; } }

.pageslist_item {
  letter-spacing: 1em; }
  @media (min-width: 769px) {
    .pageslist_item {
      margin-right: 30px;
      font-size: 1.6rem; } }
  @media (max-width: 768px) {
    .pageslist_item {
      display: inline-block; } }
  .pageslist_item.new {
    position: relative; }
    .pageslist_item.new:before {
      content: "";
      width: 6px;
      height: 6px;
      display: block;
      background-color: #00789c;
      border-radius: 50%;
      position: absolute; }
      @media (min-width: 769px) {
        .pageslist_item.new:before {
          left: 50%;
          top: -10px;
          transform: translateX(-50%); } }
      @media (max-width: 768px) {
        .pageslist_item.new:before {
          left: -15px;
          top: 50%;
          transform: translateY(-50%); } }
  @media (min-width: 769px) {
    .pageslist_item a:hover {
      color: #00789c; } }
  .pageslist_item:last-child {
    margin-right: 0; }

@media (max-width: 768px) {
  .g-header .g-nav {
    display: none;
    width: 100%;
    height: 110%;
    position: fixed;
    top: 98px;
    z-index: 9999;
    background-color: #fff;
    text-align: center;
    border-top: solid 1px #000; } }

@media (max-width: 768px) {
  .g-header .g-nav .pageslist_item {
    font-size: 2.2rem;
    margin-bottom: 20%; } }

@media (max-width: 768px) {
  .g-header .g-nav .pageslist {
    position: absolute;
    top: 7%;
    left: 50%;
    transform: translateX(-50%); } }

.g-logo_inner {
  display: flex;
  align-items: end; }

@media (min-width: 769px) {
  .g-logo_wrap {
    display: flex;
    justify-content: flex-end; } }

@media (max-width: 768px) {
  .g-logo_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px; } }

.g-logo_wrap li.logo_ias {
  display: none; }
  @media (min-width: 769px) {
    .g-logo_wrap li.logo_ias {
      width: 90px;
      margin-right: 4px;
      margin-left: 30px; } }
  @media (max-width: 768px) {
    .g-logo_wrap li.logo_ias {
      width: 60px;
      margin-right: 10px; } }
  .f-nav .g-logo_wrap li.logo_ias {
    margin-left: 0; }
    @media (min-width: 769px) {
      .f-nav .g-logo_wrap li.logo_ias {
        width: 100px;
        margin-right: 20px; } }

@media (min-width: 769px) {
  .g-logo_wrap li.logo_gcerti {
    width: 80px; } }

@media (max-width: 768px) {
  .g-logo_wrap li.logo_gcerti {
    width: 50px;
    margin-top: -5px; } }

.spmenu {
  width: 30px;
  position: absolute;
  right: 20px;
  top: 31px; }
  .spmenu .open, .spmenu .close {
    position: absolute;
    transition: opacity 200ms linear; }
  .spmenu .open {
    opacity: 1; }
  .spmenu .close {
    opacity: 0; }
  .spmenu.on .open {
    opacity: 0; }
  .spmenu.on .close {
    opacity: 1; }

.pageslist_item-top {
  font-size: 1.6rem;
  margin-bottom: 30px; }

.heading02 {
  font-size: 2.1rem; }
  @media (min-width: 769px) {
    .heading02 {
      margin-bottom: 35px; } }
  @media (max-width: 768px) {
    .heading02 {
      margin-bottom: 30px; } }
  .heading02.jp {
    letter-spacing: .14em;
    font-size: 1.8rem; }
  .heading02.mgb0 {
    margin-bottom: 0; }

.inner {
  text-align: center; }

@media (min-width: 769px) {
  #company #sec01, #company #policy {
    border-top: solid 10px #000;
    padding: 90px 0; } }

@media (max-width: 768px) {
  #company #sec01, #company #policy {
    padding: 45px 0; } }

@media (min-width: 769px) {
  #company #sec02 {
    padding: 90px 0px 50px; } }

@media (max-width: 768px) {
  #company #sec02 {
    padding: 45px 0px 45px; } }

@media (min-width: 769px) {
  #company #sec03 {
    padding: 0px 0px 90px; } }

@media (max-width: 768px) {
  #company #sec03 {
    padding: 0px 0px 25px; } }

#company .heading03 {
  font-size: 2rem;
  background-color: #000;
  color: #fff;
  padding: 12px 0px; }

#company .inner_txt {
  line-height: 1.8em;
  margin-bottom: 1.5em; }
  #company .inner_txt:last-child {
    margin-bottom: 0; }
  @media (min-width: 769px) {
    #company .inner_txt {
      font-size: 1.5rem; } }
  @media (max-width: 768px) {
    #company .inner_txt {
      font-size: 1.4rem;
      width: calc(100% - 40px);
      margin: auto;
      margin-bottom: 35px;
      text-align: justify; } }
  @media (min-width: 769px) {
    #company .inner_txt.mgb {
      margin-bottom: 70px; } }

#company .inner_txt-policy {
  line-height: 1.8em;
  margin-bottom: 40px;
  text-align: left; }
  #company .inner_txt-policy:last-child {
    margin-bottom: 0; }
  #company .inner_txt-policy--center {
    width: 100%;
    display: block;
    text-align: center; }
  @media (min-width: 769px) {
    #company .inner_txt-policy {
      font-size: 1.5rem; } }
  @media (max-width: 768px) {
    #company .inner_txt-policy {
      font-size: 1.4rem;
      width: calc(100% - 40px);
      margin: 0 auto 2em;
      text-align: justify; } }
  #company .inner_txt-policy ol li {
    padding-left: 1.3em;
    text-indent: -1.3em; }
  #company .inner_txt-policy.mgb {
    margin-bottom: 4em; }

#company .inner_txt-wrap {
  width: 95%;
  max-width: 600px;
  margin: auto;
  margin-bottom: 50px; }

#company .companybox {
  max-width: 670px;
  margin: auto;
  margin-bottom: 30px;
  padding-bottom: 35px;
  border-bottom: solid 1px; }
  @media (max-width: 768px) {
    #company .companybox {
      width: calc(100% - 40px); } }
  #company .companybox:last-child {
    border-bottom: none;
    margin-bottom: 0px;
    padding-bottom: 0px; }

#company .company_heading03 {
  font-size: 1.8rem;
  line-height: 1.8em;
  margin-bottom: 11px; }

#company .company_txt {
  font-size: 1.4rem; }
  #company .company_txt.mgb {
    margin-bottom: 1em; }
  @media (max-width: 768px) {
    #company .company_txt.en {
      line-height: 1.5em;
      word-wrap: break-word; } }
  #company .company_txt a {
    color: #00789c; }

#company .bgG {
  background-color: #f2f2f2; }

#company .tableFrame {
  max-width: 930px;
  margin: auto; }
  @media (min-width: 769px) {
    #company .tableFrame {
      width: 95%; } }
  @media (max-width: 768px) {
    #company .tableFrame {
      width: calc(100% - 40px); } }

#company .table {
  width: 100%;
  text-align: left; }
  @media (min-width: 769px) {
    #company .table {
      font-size: 1.4rem; } }
  @media (max-width: 768px) {
    #company .table {
      font-size: 1.3rem;
      table-layout: fixed;
      word-break: break-all;
      word-wrap: break-word; } }
  #company .table td {
    background-color: #fff;
    line-height: 1.5em; }
    @media (min-width: 769px) {
      #company .table td {
        padding: 10px 20px; } }
    @media (max-width: 768px) {
      #company .table td {
        letter-spacing: 0em;
        padding: 5px 10px; } }
    #company .table td:first-child {
      border-right: solid 4px #f2f2f2; }
      @media (min-width: 769px) {
        #company .table td:first-child {
          width: 15.6%; } }
      @media (max-width: 768px) {
        #company .table td:first-child {
          width: 30%; } }
      #company .table td:first-child .en {
        display: block;
        line-height: 19px; }
    #company .table td:last-child {
      vertical-align: middle; }
    #company .table td.lineH_s {
      line-height: 1em; }
      #company .table td.lineH_s span {
        line-height: 2.1em; }
  @media (min-width: 769px) {
    #company .table tr {
      border-top: solid 10px #f2f2f2; } }
  @media (max-width: 768px) {
    #company .table tr {
      border-top: solid 5px #f2f2f2; } }
  #company .table .break {
    word-break: break-all; }
  #company .table .em {
    font-size: 1.5rem; }
  #company .table .small {
    font-size: 11px;
    margin-right: 1em; }
  #company .table .name {
    font-size: 11px; }

#company .access_txt {
  font-size: 1.4rem; }
  @media (min-width: 769px) {
    #company .access_txt {
      margin: 30px 0px; } }
  @media (max-width: 768px) {
    #company .access_txt {
      width: calc(100% - 40px);
      margin: 20px auto; } }

#company #map {
  height: 400px; }
  @media (min-width: 769px) {
    #company #map {
      width: 100%; } }
  @media (max-width: 768px) {
    #company #map {
      width: calc(100% - 40px);
      margin: 0px auto 20px; } }

@media (min-width: 769px) {
  #company a:hover {
    color: #00789c; } }

@media (max-width: 768px) {
  #company .en {
    word-wrap: break-word; } }

.policy-logo_wrap {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 769px) {
    .policy-logo_wrap li.logo_ias {
      width: 204px;
      margin-right: 4px;
      margin-left: 30px; } }
  @media (max-width: 768px) {
    .policy-logo_wrap li.logo_ias {
      width: 120px; } }
  @media (min-width: 769px) {
    .policy-logo_wrap li.logo_gcerti {
      width: 168px; } }
  @media (max-width: 768px) {
    .policy-logo_wrap li.logo_gcerti {
      width: 100px;
      margin-top: -5px; } }

@media (min-width: 769px) {
  #contact .inner-wrap {
    border-top: solid 10px #000; } }

@media (min-width: 769px) {
  #contact #sec01 {
    padding: 90px 0; } }

@media (max-width: 768px) {
  #contact #sec01 {
    padding: 45px 0; } }

#contact #sec01 .mgb {
  margin-bottom: 30px; }

#contact #sec01 a:hover {
  color: #00789c; }

#contact .contact_txt {
  font-size: 1.4rem;
  line-height: 1.8em;
  margin-bottom: .8em; }
  @media (max-width: 768px) {
    #contact .contact_txt {
      text-align: left; } }

#contact .contact_note {
  font-size: 1.2rem;
  line-height: 1.8em; }
  @media (max-width: 768px) {
    #contact .contact_note {
      text-align: left; } }

#contact .inner {
  max-width: 600px;
  margin: auto; }
  @media (min-width: 769px) {
    #contact .inner {
      width: 95%; } }
  @media (max-width: 768px) {
    #contact .inner {
      width: calc(100% - 40px); } }

@media (min-width: 769px) {
  #contact .form {
    margin-top: 20px; } }

#contact .form table {
  border-collapse: separate; }
  @media (min-width: 769px) {
    #contact .form table {
      margin-left: -73px;
      border-spacing: 0px 30px; } }
  @media (max-width: 768px) {
    #contact .form table {
      border-spacing: 0px 20px; } }

#contact .form tbody, #contact .form table {
  width: 100%; }

#contact .form th {
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  text-align: left;
  font-weight: bold;
  vertical-align: middle; }
  @media (max-width: 768px) {
    #contact .form th {
      display: block;
      margin-bottom: 10px; } }

#contact .form td {
  text-align: left;
  vertical-align: middle;
  border: solid 1px #000; }
  @media (min-width: 769px) {
    #contact .form td {
      width: 73%;
      max-width: 450px; } }
  @media (max-width: 768px) {
    #contact .form td {
      width: 100%;
      display: block; } }
  #contact .form td input, #contact .form td textarea {
    width: 100%;
    font-size: 1.6rem;
    letter-spacing: 0.1em;
    color: #000; }
    @media (min-width: 769px) {
      #contact .form td input, #contact .form td textarea {
        padding: 10px 10px; } }
    @media (max-width: 768px) {
      #contact .form td input, #contact .form td textarea {
        padding: 10px;
        line-height: 1.3em; } }
    #contact .form td input::placeholder, #contact .form td textarea::placeholder {
      color: #ccc; }
  #contact .form td textarea {
    height: 190px;
    color: #000; }

.privacyBox_agree {
  position: relative;
  margin: auto; }
  @media (min-width: 769px) {
    .privacyBox_agree {
      width: 450px; } }

.privacyBox_txt {
  font-size: 1.2rem;
  text-decoration: underline;
  margin-bottom: 1em; }
  @media (min-width: 769px) {
    .privacyBox_txt a:hover {
      color: #00789c; } }

.agree {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 1.2rem;
  color: #444; }
  @media (min-width: 769px) {
    .agree {
      padding-top: 13px;
      padding-left: 25px; } }
  @media (max-width: 768px) {
    .agree {
      padding-top: 5px;
      padding-left: 15%;
      text-align: left; } }

.agree_indicator {
  position: absolute;
  top: 5px;
  height: 35px;
  width: 35px;
  border: 1px solid #000;
  border-radius: 2px;
  background-color: #FFFFFF; }
  @media (min-width: 769px) {
    .agree_indicator {
      left: -27px; } }
  @media (max-width: 768px) {
    .agree_indicator {
      left: 0px; } }

.agree input:checked ~
.agree_indicator:after {
  display: block; }

@media (max-width: 768px) {
  .agree {
    width: 90%; } }

.agree .agree_indicator:after {
  width: 12px;
  height: 20px;
  left: 11px;
  top: 3px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); }

.agree_indicator:after {
  content: '';
  position: absolute;
  display: none; }

label input:checked + .mwform-checkbox-field-text + .check::after {
  display: block;
  position: absolute;
  width: 16px;
  height: 8px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg);
  content: "";
  box-sizing: border-box; }
  @media (min-width: 769px) {
    label input:checked + .mwform-checkbox-field-text + .check::after {
      left: -16px;
      top: 16px; } }
  @media (max-width: 768px) {
    label input:checked + .mwform-checkbox-field-text + .check::after {
      left: 10px;
      top: 16px; } }

label input:disabled + .mwform-checkbox-field-text + .check:before {
  background-color: #000; }

label input:checked:disabled + .mwform-checkbox-field-text + .check::after {
  border-left: 3px solid #000;
  border-bottom: 3px solid 000; }

.mw_wp_form .error {
  position: absolute; }

.mw_wp_form_preview .form td {
  border: none !important;
  padding-left: 3em; }

.mw_wp_form_preview .privacyBox_agree {
  display: none; }

.mw_wp_form_preview .preview_txt {
  display: block; }

.mw_wp_form_preview .contact_txt,
.mw_wp_form_preview .contact_note {
  display: none; }

.preview_txt {
  display: none;
  font-size: 1.4rem; }

.mw_wp_form_complete .contact_txt,
.mw_wp_form_complete .contact_note {
  display: none; }

.submitBtn,
.preview_submitBtn {
  font-size: 1.8rem;
  margin: auto;
  margin-top: 35px;
  padding: 15px 0;
  border: solid 1px #000;
  background-color: #fff;
  transition: all .3s 0s ease;
  display: block;
  color: #000; }
  @media (min-width: 769px) {
    .submitBtn,
    .preview_submitBtn {
      width: 450px;
      cursor: pointer; } }
  @media (max-width: 768px) {
    .submitBtn,
    .preview_submitBtn {
      width: 100%; } }
  .submitBtn a,
  .preview_submitBtn a {
    transition: all .3s 0s ease; }
  .submitBtn:hover,
  .preview_submitBtn:hover {
    background-color: #000;
    color: #fff; }
    .submitBtn:hover a,
    .preview_submitBtn:hover a {
      color: #fff; }

#modal {
  transition: all 1s ease-in-out;
  min-height: 100vh; }

#modal-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 9999; }

#modal-content {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  z-index: 9999; }
  @media (min-width: 769px) {
    #modal-content {
      min-height: 100vh;
      width: 100%;
      max-width: 850px; } }
  @media (max-width: 768px) {
    #modal-content {
      height: 100%;
      width: 85%; } }

#modal-closeBtn {
  position: absolute; }
  @media (min-width: 769px) {
    #modal-closeBtn {
      width: 30px;
      top: 60px;
      right: 60px;
      cursor: pointer; } }
  @media (max-width: 768px) {
    #modal-closeBtn {
      width: 20px;
      top: 36px;
      right: 30px;
      cursor: pointer; } }

@media (min-width: 769px) {
  .modal-show {
    overflow: hidden; } }

.modal-iframe {
  width: 100%; }
  @media (min-width: 769px) {
    .modal-iframe {
      height: 100% !important; } }

@media (max-width: 768px) {
  .modal-html {
    height: auto !important; } }

@media (min-width: 769px) {
  .modal-body {
    width: 730px !important;
    min-width: 730px !important; } }

@media (max-width: 768px) {
  .modal-body {
    width: 100% !important; } }

.modal-article {
  overflow-y: scroll; }
  @media (min-width: 769px) {
    .modal-article {
      height: 100vh;
      padding: 60px; } }
  @media (max-width: 768px) {
    .modal-article {
      padding: 30px;
      height: 100%;
      width: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      -webkit-transform: translateZ(0); } }
  .modal-article__inner {
    font-size: 1.9rem; }
  .modal-article__title {
    font-weight: bold;
    letter-spacing: 0.1em; }
    @media (min-width: 769px) {
      .modal-article__title {
        font-size: 1.7rem;
        margin-bottom: 60px;
        padding-right: 40px; } }
    @media (max-width: 768px) {
      .modal-article__title {
        font-size: 1.6rem;
        margin-bottom: 30px;
        padding-right: 40px; } }

.modal-txt__group {
  margin-bottom: 40px; }

.modal-txt {
  letter-spacing: 0.01em; }
  @media (min-width: 769px) {
    .modal-txt {
      font-size: 1.4rem;
      line-height: 1.8em;
      margin-bottom: 30px; } }
  @media (max-width: 768px) {
    .modal-txt {
      font-size: 1.3rem;
      line-height: 1.8em;
      margin-bottom: 20px; } }

.modal-link {
  letter-spacing: 0.01em;
  line-height: 1.8em; }
  @media (min-width: 769px) {
    .modal-link {
      font-size: 1.5rem;
      margin-bottom: 20px; } }
  @media (max-width: 768px) {
    .modal-link {
      font-size: 1.2rem;
      margin-bottom: 20px; } }
  .modal-link a {
    display: block; }
    @media (min-width: 769px) {
      .modal-link a:hover {
        color: #00789c; } }
  .modal-link dd a {
    display: inline-block;
    word-break: break-all; }

.modal__list {
  color: #888; }
  @media (min-width: 769px) {
    .modal__list {
      font-size: 1.4rem;
      line-height: 2em; } }
  @media (max-width: 768px) {
    .modal__list {
      font-size: 1.3rem;
      line-height: 1.8em; } }

.modal-dest {
  color: #888;
  display: flex;
  align-items: center; }
  @media (min-width: 769px) {
    .modal-dest {
      font-size: 1.4rem;
      line-height: 2em; } }
  @media (max-width: 768px) {
    .modal-dest {
      font-size: 1.3rem;
      line-height: 1.8em; } }
  .modal-dest__group {
    margin-bottom: 50px; }
  .modal-dest__right {
    display: flex;
    align-items: center; }
    .modal-dest__right a {
      display: block; }
      .modal-dest__right a:hover {
        opacity: .8; }

.modal-photoBox__wrap {
  margin-bottom: 30px; }

#news_detail .inner-wrap {
  border-top: solid 10px #000; }

#news_detail .inner {
  max-width: 1000px;
  margin: auto; }
  @media (min-width: 769px) {
    #news_detail .inner {
      width: 95%;
      padding: 90px 0; } }
  @media (max-width: 768px) {
    #news_detail .inner {
      width: calc(100% - 40px);
      padding: 45px 0; } }

#news_detail .inner_head {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  #news_detail .inner_head--link {
    display: block;
    position: relative;
    padding-right: 10px; }
    @media (min-width: 769px) {
      #news_detail .inner_head--link {
        margin: 20px 0; } }
    @media (max-width: 768px) {
      #news_detail .inner_head--link {
        font-size: 1.3rem;
        margin: 10px 0; } }
    #news_detail .inner_head--link:hover {
      color: #00789c; }
    #news_detail .inner_head--link::after {
      content: "";
      width: 6px;
      height: 6px;
      display: block;
      border-top: solid 1px;
      border-right: solid 1px;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto; }

#news_detail h2 {
  font-size: 1.8rem;
  text-align: left; }

#news_detail .articleBox {
  font-size: 14px;
  margin-top: 16px;
  text-align: left; }

#news_detail .articleBox_top {
  border-top: solid 1px;
  border-bottom: solid 1px; }
  @media (min-width: 769px) {
    #news_detail .articleBox_top {
      padding: 30px 0;
      margin-bottom: 30px; } }
  @media (max-width: 768px) {
    #news_detail .articleBox_top {
      padding: 20px 0;
      margin-bottom: 20px; } }

#news_detail .tag {
  background-color: #00789b;
  color: #fff;
  font-size: 10px; }
  @media (min-width: 769px) {
    #news_detail .tag {
      padding: 6px; } }
  @media (max-width: 768px) {
    #news_detail .tag {
      padding: 4px; } }

#news_detail .date {
  line-height: 22px;
  display: inline-block; }
  @media (min-width: 769px) {
    #news_detail .date {
      font-size: 14px;
      margin-left: 20px; } }
  @media (max-width: 768px) {
    #news_detail .date {
      font-size: 12px;
      margin-left: 10px; } }

#news_detail .name {
  line-height: 22px; }
  @media (min-width: 769px) {
    #news_detail .name {
      font-size: 14px;
      margin-left: 20px;
      display: inline-block; } }
  @media (max-width: 768px) {
    #news_detail .name {
      font-size: 12px;
      display: block;
      margin-top: 5px; } }

@media (min-width: 769px) {
  #news_detail .articleBox_tit {
    font-size: 2.1rem;
    margin-top: 20px;
    line-height: 1.4em; } }

@media (max-width: 768px) {
  #news_detail .articleBox_tit {
    font-size: 1.4rem;
    margin-top: 10px;
    line-height: 1.6em; } }

#news_detail .articleBox_txt {
  line-height: 1.8em; }
  @media (min-width: 769px) {
    #news_detail .articleBox_txt {
      margin: 30px 0; } }
  @media (max-width: 768px) {
    #news_detail .articleBox_txt {
      font-size: 1.3rem;
      line-height: 1.8em;
      margin: 20px 0; } }

#news_detail .addlink {
  display: block;
  margin-top: 1em;
  padding-left: 1em;
  position: relative;
  font-size: 1.2rem; }
  @media (max-width: 768px) {
    #news_detail .addlink {
      font-size: 1.2rem;
      line-height: 1.8em; } }
  #news_detail .addlink a {
    color: #00789c; }
  #news_detail .addlink a:before {
    content: "";
    width: 6px;
    height: 6px;
    display: block;
    border-top: solid 1px;
    border-right: solid 1px;
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto; }

@media (min-width: 769px) {
  #news_detail .articleBox_img {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center; } }

@media (max-width: 768px) {
  #news_detail .articleBox_img {
    margin-bottom: 20px;
    max-width: 500px;
    margin: auto; } }

@media (min-width: 769px) {
  #news_detail .articleBox_imgItem {
    width: 49.5%;
    margin-bottom: 10px; } }

@media (max-width: 768px) {
  #news_detail .articleBox_imgItem {
    margin-bottom: 5px; } }

@media (min-width: 769px) {
  #news_detail .articleBox_imgItem:first-child {
    margin-right: 1%; } }

@media (max-width: 768px) {
  #news_detail .articleBox_imgItem:first-child {
    margin-bottom: 5px; } }

@media (min-width: 769px) {
  #news_detail .articleBox_imgItem:last-child {
    margin-right: 0; } }

@media (max-width: 768px) {
  #news_detail .articleBox_imgItem:last-child {
    margin-bottom: 0px; } }

#news_detail .inner_bottom {
  text-align: center; }
  @media (max-width: 768px) {
    #news_detail .inner_bottom {
      font-size: 1.3rem; } }

#news_detail .inner_bottom-list {
  display: flex;
  justify-content: center; }

@media (min-width: 769px) {
  #news_detail .inner_bottom-item {
    margin: 20px; } }

@media (max-width: 768px) {
  #news_detail .inner_bottom-item {
    margin: 10px 20px;
    font-size: 1.3rem;
    line-height: 1.8em; } }

#news_detail .inner_bottom-item--link {
  display: block;
  position: relative; }
  #news_detail .inner_bottom-item--link:hover {
    color: #00789c; }
  #news_detail .inner_bottom-item--link::after, #news_detail .inner_bottom-item--link::before {
    width: 6px;
    height: 6px;
    display: block;
    border-top: solid 1px;
    border-right: solid 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto; }
  #news_detail .inner_bottom-item--link.left {
    padding-left: 10px; }
    #news_detail .inner_bottom-item--link.left::before {
      content: "";
      transform: rotate(-135deg);
      left: 0; }
  #news_detail .inner_bottom-item--link.right {
    padding-right: 10px; }
    #news_detail .inner_bottom-item--link.right::after {
      content: "";
      transform: rotate(45deg);
      right: 0; }

#news {
  background-color: #f2f2f2; }
  #news .inner-wrap {
    border-top: solid 10px #000; }
  #news .inner {
    max-width: 1000px;
    margin: auto; }
    @media (min-width: 769px) {
      #news .inner {
        width: 95%;
        padding: 90px 0; } }
    @media (max-width: 768px) {
      #news .inner {
        width: calc(100% - 40px);
        padding: 45px 0; } }
  #news .box {
    text-align: left; }
  #news .heading03 {
    font-size: 1.2rem;
    padding-bottom: 16px;
    background-color: #f2f2f2;
    text-align: left; }
  @media (min-width: 769px) {
    #news .inner-wrap__box {
      display: flex;
      justify-content: space-between; } }
  @media (min-width: 769px) {
    #news .inner-wrap__left {
      width: 78%; } }
  #news .inner-wrap__left .box {
    margin-top: 20px; }
    #news .inner-wrap__left .box:first-child {
      margin-top: 0; }
  #news .inner-wrap__right {
    background-color: #fff; }
    @media (min-width: 769px) {
      #news .inner-wrap__right {
        width: 20%; } }
    @media (max-width: 768px) {
      #news .inner-wrap__right {
        margin-top: 30px; } }
  #news .group {
    background-color: #fff;
    margin-bottom: 6px; }
    #news .group--link {
      display: block;
      padding: 20px; }
      #news .group--link:hover {
        color: #00789c; }
    #news .group:last-child {
      margin-bottom: 0; }
  #news .tag {
    background-color: #00789b;
    color: #fff;
    font-size: 10px; }
    @media (min-width: 769px) {
      #news .tag {
        padding: 6px; } }
    @media (max-width: 768px) {
      #news .tag {
        padding: 4px; } }
  #news .date {
    line-height: 22px;
    display: inline-block; }
    @media (min-width: 769px) {
      #news .date {
        font-size: 14px;
        margin-left: 20px; } }
    @media (max-width: 768px) {
      #news .date {
        font-size: 12px;
        margin-left: 10px; } }
  #news .name {
    line-height: 22px; }
    @media (min-width: 769px) {
      #news .name {
        font-size: 14px;
        margin-left: 20px;
        display: inline-block; } }
    @media (max-width: 768px) {
      #news .name {
        font-size: 12px;
        display: block;
        margin-top: 5px; } }
  #news .article {
    margin-top: 16px; }
    @media (min-width: 769px) {
      #news .article {
        font-size: 14px; } }
    @media (max-width: 768px) {
      #news .article {
        font-size: 1.3rem; } }
  #news .item {
    font-size: 14px;
    letter-spacing: .1em;
    margin: 0 20px; }
    #news .item a {
      padding: 20px 0;
      display: block;
      border-bottom: solid 1px #000;
      position: relative; }
      @media (min-width: 769px) {
        #news .item a {
          padding: 20px 0; } }
      @media (max-width: 768px) {
        #news .item a {
          padding: 16px 0; } }
      #news .item a:hover {
        color: #00789c; }
      #news .item a::after {
        content: "";
        width: 10px;
        height: 10px;
        display: block;
        border-top: solid 1px;
        border-right: solid 1px;
        transform: rotate(45deg);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto; }
    #news .item:last-child a {
      border: none; }

@media (min-width: 769px) {
  #security {
    padding: 90px 0;
    border-top: solid 10px #000; } }

@media (max-width: 768px) {
  #security {
    padding: 45px 0; } }

#security table {
  text-align: left;
  font-size: 13px;
  border-top: solid 1px #000; }
  @media (min-width: 769px) {
    #security table {
      width: 100%; } }
  @media (max-width: 768px) {
    #security table {
      width: calc(100% - 40px);
      margin: auto; } }
  #security table tr {
    border-bottom: solid 1px #000; }
    #security table tr th {
      width: 115px; }
    #security table tr th, #security table tr td {
      padding: 15px 0; }

#security .security_logo {
  width: 150px;
  margin: 50px auto 0; }

#home {
  transition: all 1s ease-in-out;
  min-height: 100vh; }
  @media (min-width: 769px) {
    #home {
      background: repeating-linear-gradient(-45deg, #eee 0, #eee 3px, #fff 3px, #fff 6px);
      padding-top: 168px; } }
  @media (max-width: 768px) {
    #home {
      padding-top: 160px; } }

.filter {
  background-color: #f2f2f2;
  position: fixed;
  width: 100%;
  z-index: 9998; }
  @media (min-width: 769px) {
    .filter {
      padding: 18px 3.33%;
      top: 110px; } }
  @media (max-width: 768px) {
    .filter {
      padding: 20px;
      top: 100px; } }
  .filter .arrowDown {
    width: 15px;
    height: 14px;
    position: absolute;
    right: 0px;
    top: 5px; }

.filterWrap {
  display: flex;
  align-items: top; }
  @media (min-width: 769px) {
    .filterWrap {
      justify-content: flex-end; } }
  @media (max-width: 768px) {
    .filterWrap {
      justify-content: left;
      position: relative; } }
  @media (min-width: 769px) {
    .filterWrap a:hover {
      color: #00789c; } }

@media (min-width: 769px) {
  .filterWrap_tit {
    margin-right: 25px; } }

@media (max-width: 768px) {
  .filterWrap_tit {
    font-size: 1.4rem;
    margin-right: 10px;
    margin-top: 1px; } }

@media (min-width: 769px) {
  .filterWrap_list {
    display: flex; } }

@media (max-width: 768px) {
  .filterWrap_list {
    display: none;
    width: calc(100% + 40px);
    position: absolute;
    left: -20px;
    top: 30px;
    padding: 0px 10px 20px 80px;
    background-color: #f2f2f2;
    z-index: 9; } }

@media (min-width: 769px) {
  .filterWrap_item {
    margin-right: 25px; } }

@media (max-width: 768px) {
  .filterWrap_item {
    margin-bottom: 10px; } }

.filterWrap_item a {
  color: #aeaeae; }

.filterWrap_item:last-child {
  margin-right: 0; }

.filterWrap_item .active {
  color: #000; }
  @media (max-width: 768px) {
    .filterWrap_item .active {
      display: none; } }

.boxesFrame {
  display: flex;
  flex-wrap: wrap; }

.boxes {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden; }
  @media (min-width: 769px) {
    .boxes {
      width: 20%;
      cursor: pointer; } }
  @media (max-width: 768px) {
    .boxes {
      width: 100%; } }
  .boxes.hide {
    display: none; }

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .boxes {
    width: 50%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .boxes {
    width: 25%; } }

@media only screen and (min-width: 1800px) {
  .boxes {
    width: 16.66%; } }

@media only screen and (min-width: 2400px) {
  .boxes {
    width: 16.66%; } }

.boxesItem {
  display: block; }
  @media (min-width: 769px) {
    .boxesItem:hover .thumbnail_tit {
      opacity: 1; }
    .boxesItem:hover h2 {
      transform: translateX(-50%) translateY(-50%) scale(1); }
    .boxesItem:hover .thumbnail {
      opacity: 0;
      transform: scale(1.3); } }

.thumbnail {
  transition: opacity 400ms ease-in-out,transform 400ms ease-in-out;
  transform: scale(1);
  opacity: 1; }

.thumbnail_tit {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  transform: scale(1);
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.65) 100%);
  opacity: 0;
  transition: opacity 350ms ease-in-out; }
  .thumbnail_tit h2 {
    width: 60%;
    font-size: 1.6rem;
    line-height: 26px;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(1.5);
    transition: transform 200ms ease-in-out; }

.viewmore {
  cursor: pointer;
  background-color: #fff;
  position: relative;
  display: none; }

.viewmore_btn {
  font-size: 1.8rem;
  text-align: center;
  padding: 43px 0; }
