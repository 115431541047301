#news_detail{
  .inner-wrap {
    border-top: solid 10px #000;
  }
  .inner{
    max-width: 1000px;
    margin: auto;
    @include PC {
      width: 95%;
      padding: 90px 0;
    }
    @include SP {
      width: calc(100% - 40px);
      padding: 45px 0;
    }
  }
  .inner_head{
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--link{
      display: block;
      position: relative;
      padding-right: 10px;
      @include PC {
        margin: 20px 0;
      }
      @include SP {
        font-size: 1.3rem;
        margin: 10px 0;
      }
      &:hover{color: #00789c;}
      &::after{
        content: "";
        width: 6px;
        height: 6px;
        display:  block;
        border-top: solid 1px;
        border-right: solid 1px;
        transform: rotate(45deg);
        position:  absolute;
        top: 0;
        bottom:  0;
        right:  0;
        margin:  auto;
      }
    }
  }
  h2{
    font-size: 1.8rem;
    text-align: left;
  }
  .articleBox{
    font-size: 14px;
    margin-top: 16px;
    text-align: left;
  }
  .articleBox_top{
    border-top: solid 1px;
    border-bottom: solid 1px;
    @include PC {
      padding: 30px 0;
      margin-bottom: 30px;
    }
    @include SP {
      padding: 20px 0;
      margin-bottom: 20px;
    }
  }
  .tag{
    background-color: #00789b;
    color: #fff;
    font-size: 10px;
    @include PC {padding: 6px;}
    @include SP {padding: 4px;}
  }
  .date{
    line-height: 22px;
    display: inline-block;
    @include PC {
      font-size: 14px;
      margin-left: 20px;
    }
    @include SP {
      font-size: 12px;
      margin-left: 10px;
    }
  }
  .name{
    line-height: 22px;
    @include PC {
      font-size: 14px;
      margin-left: 20px;
      display: inline-block;
    }
    @include SP {
      font-size: 12px;
      display: block;
      margin-top: 5px;
    }
  }
  .articleBox_tit{
    @include PC {
      font-size: 2.1rem;
      margin-top: 20px;
      line-height: 1.4em;
    }
    @include SP {
      font-size: 1.4rem;
      margin-top: 10px;
      line-height: 1.6em;
    }
  }
  .articleBox_txt{
    line-height: 1.8em;
    @include PC {margin: 30px 0;}
    @include SP {
      font-size: 1.3rem;
      line-height: 1.8em;
      margin: 20px 0;
    }
  }
  .addlink{
    display: block;
    margin-top: 1em;
    padding-left: 1em;
    position: relative;
    font-size: 1.2rem;
    @include SP {
      font-size: 1.2rem;
      line-height: 1.8em;
    }
    a{color: #00789c;}
    a:before{
      content: "";
      width: 6px;
      height: 6px;
      display:  block;
      border-top: solid 1px;
      border-right: solid 1px;
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
  .articleBox_img{
    @include PC {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
    }
    @include SP {
      margin-bottom: 20px;
      max-width: 500px;
      margin: auto;
    }
  }
  .articleBox_imgItem{
    @include PC {
      width: 49.5%;
      margin-bottom: 10px;
    }
    @include SP {
      margin-bottom: 5px;
    }
    &:first-child{
      @include PC {margin-right: 1%;}
      @include SP {margin-bottom: 5px;}
    }
    &:last-child{
      @include PC {margin-right: 0;}
      @include SP {margin-bottom: 0px;}
    }
  }
  .inner_bottom{
    text-align: center;
    @include SP {font-size: 1.3rem;}
  }
  .inner_bottom-list{
    display: flex;
    justify-content: center;
  }
  .inner_bottom-item{
    @include PC {margin: 20px;}
    @include SP {
      margin: 10px 20px;
      font-size: 1.3rem;
      line-height: 1.8em;
    }
    &--link{
      display: block;
      position: relative;
      &:hover{color: #00789c;}
      &::after,
      &::before{
        width: 6px;
        height: 6px;
        display:  block;
        border-top: solid 1px;
        border-right: solid 1px;
        position:  absolute;
        top: 0;
        bottom:  0;
        margin:  auto;
      }
      &.left{
        padding-left: 10px;
        &::before{
          content: "";
          transform: rotate(-135deg);
          left: 0;
        }
      }
      &.right{
        padding-right: 10px;
        &::after{
          content: "";
          transform: rotate(45deg);
          right:  0;
        }
      }
    }
  }
}
