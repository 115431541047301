.heading02{
  font-size: 2.1rem;
  @include PC {margin-bottom: 35px;}
  @include SP {margin-bottom: 30px;}
  &.jp{
    letter-spacing: .14em;
    font-size: 1.8rem;
  }
  &.mgb0{
    margin-bottom: 0;
  }
}
.inner{
  text-align: center;
}
#company{
  #sec01,#policy{
    @include PC {
      border-top: solid 10px #000;
      padding: 90px 0;
    }
    @include SP {padding: 45px 0;}
  }
  #sec02{
    @include PC {padding: 90px 0px 50px;}
    @include SP {padding: 45px 0px 45px;}
  }
  #sec03{
    @include PC {padding: 0px 0px 90px;}
    @include SP {padding: 0px 0px 25px;}
  }

  .heading03{
    font-size: 2rem;
    background-color: #000;
    color: #fff;
    padding: 12px 0px;
  }
  .inner_txt{
    line-height: 1.8em;
    margin-bottom: 1.5em;
    &:last-child{margin-bottom: 0;}
    @include PC {
      font-size: 1.5rem;
      // margin-bottom: 70px;
    }
    @include SP {
      font-size: 1.4rem;
      width: calc(100% - 40px);
      margin: auto;
      margin-bottom: 35px;
      text-align: justify;
    }
    &.mgb{
      @include PC {margin-bottom: 70px;}
    }
  }
  .inner_txt-policy{
    line-height: 1.8em;
    margin-bottom: 40px;
    text-align: left;
    &:last-child{margin-bottom: 0;}
    &--center{
      width: 100%;
      display: block;
      text-align: center;
    }

    @include PC {
      font-size: 1.5rem;
    }
    @include SP {
      font-size: 1.4rem;
      width: calc(100% - 40px);
      margin: 0 auto 2em;
      text-align: justify;
    }
    ol{
      li{
        padding-left: 1.3em;
        text-indent: -1.3em;
      }
    }
    &.mgb{
      margin-bottom: 4em;
    }
  }
  .inner_txt-wrap{
    width: 95%;
    max-width: 600px;
    margin: auto;
    margin-bottom: 50px;
  }
  .companybox{
    max-width: 670px;
    margin: auto;
    margin-bottom: 30px;
    padding-bottom: 35px;
    border-bottom: solid 1px;
    @include SP {width: calc(100% - 40px);}
    &:last-child{
      border-bottom: none;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
  .company_heading03{
    font-size: 1.8rem;
    line-height: 1.8em;
    margin-bottom: 11px;
  }
  .company_txt{
    font-size: 1.4rem;

    &.mgb{
      margin-bottom: 1em;
    }

    &.en{
      @include SP {
        line-height: 1.5em;
        word-wrap:break-word;
      }
    }
    a{
      color: #00789c;
    }
  }
  .bgG{
    background-color: #f2f2f2;
  }
  .tableFrame{
    max-width: 930px;
    margin: auto;
    @include PC {
      width: 95%;
    }
    @include SP {
      width: calc(100% - 40px);
    }
  }
  .table{
    width: 100%;
    text-align: left;
    @include PC {font-size: 1.4rem;}
    @include SP {
      font-size: 1.3rem;
      table-layout: fixed;
      word-break: break-all;
      word-wrap: break-word;
    }
    td{
      background-color: #fff;
      line-height: 1.5em;
      @include PC {padding: 10px 20px;}
      @include SP {
        letter-spacing: 0em;
        padding: 5px 10px;
      }
      &:first-child{
        border-right: solid 4px #f2f2f2;
        @include PC {width: 15.6%;}
        @include SP {width: 30%;}
        .en{
          display:block;
          line-height: 19px;
        }
      }
      &:last-child{
        vertical-align: middle;
      }
      &.lineH_s{
        line-height: 1em;
        span{
          line-height: 2.1em;
        }
      }
    }
    tr{
      @include PC {border-top: solid 10px #f2f2f2;}
      @include SP {border-top: solid 5px #f2f2f2;}
    }
    .break{word-break: break-all;}
    .em{
      font-size: 1.5rem;
    }
    .small{
      font-size: 11px;
      margin-right: 1em;
    }
    .name{
      font-size: 11px;
    }
  }
  .access_txt{
    font-size: 1.4rem;
    @include PC {margin: 30px 0px;}
    @include SP {
      width: calc(100% - 40px);
      margin: 20px auto;
    }
  }
  #map {
    height: 400px;
    @include PC { width: 100%;}
    @include SP {
      width: calc(100% - 40px);
      margin: 0px auto 20px;
    }
  }
  a{
    @include PC {
      &:hover{
        color: #00789c;
      }
    }
  }
  .en{
    @include SP {
      word-wrap: break-word;
      // word-break: break-all;
    }
  }
}
.policy-logo_wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  li{
    &.logo_ias{
      @include PC {
        width: 204px;
        margin-right: 4px;
        margin-left: 30px;
      }
      @include SP {
        width: 120px;
      }
    }
    &.logo_gcerti{
      @include PC {
        width: 168px;
      }
      @include SP {
        width: 100px;
        margin-top: -5px;
      }
    }
  }
}