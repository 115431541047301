footer{
  // background-color: #000;
  color: #000;
  border-top: solid 2px;
}
.f-nav{
  position: relative;
   @include PC {
    padding: 30px 3.33% 30px;
    display: flex;
    align-items: end;
    justify-content: space-between;
  }
   @include SP {padding: 30px 20px 50px;}
   &_wrap{
    @include PC {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @media screen and (max-width: 1300px) {
        display: block;
      }
    }
   }
  .pageslist_item a{
    color: #000;
  }
  .pageslist{
    @include PC {
      margin-right: 50px;
      flex-wrap: wrap;
      @media screen and (max-width: 1300px) {
        margin-right: 20px;
      }
    }
    @include SP {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      margin-bottom: 5px;
    }
  }
  .pageslist_item{
    @include PC {
      font-size: 1.4rem;
      @media screen and (max-width: 1300px) {
        margin-right: 4%;
      }
    }
    @include SP {
      font-size: 1.3rem;
      margin-bottom: 5px;
    }
  }
}
.f-copy{
  letter-spacing: .1em;
  @include PC {
    font-size: 1rem;
    white-space: nowrap;
    margin-top: 30px;

    // padding: 30px 3.33%;
    // position: absolute;
    // right: 0;
    // top: 0;
  }
  @include SP {
    font-size: 1rem;
    padding-top: 10px;
    text-align: center;
  }
}