#security{
  @include PC {
    padding: 90px 0;
    border-top: solid 10px #000;
  }
  @include SP {padding: 45px 0;}
  table{
    
    text-align: left;
    font-size: 13px;
    border-top: solid 1px #000;
    @include PC {
      width: 100%;
    }
    @include SP {
      width: calc(100% - 40px);
      margin: auto;
    }
    tr{
      border-bottom: solid 1px #000;
      th{
        width: 115px;
      }
      th,td{
        padding: 15px 0;
      }
    }
  }
  .security_logo{
    width: 150px;
    margin: 50px auto 0;
  }
}

