#contact{
  .inner-wrap{
    @include PC {border-top: solid 10px #000;}
  }
  #sec01{
    @include PC {
      padding: 90px 0;
    }
    @include SP {padding: 45px 0;}
    .mgb{
      margin-bottom: 30px;
    }
    a{
      &:hover{
        color: #00789c;
      }
    }
  }
  .contact_txt{
    font-size: 1.4rem;
    line-height: 1.8em;
    margin-bottom: .8em;
    @include SP {text-align: left;}
  }
  .contact_note{
    font-size: 1.2rem;
    line-height: 1.8em;
    @include SP {text-align: left;}
  }
  .inner{
    max-width: 600px;
    margin: auto;
    @include PC {
      width: 95%;
    }
    @include SP {
      width: calc(100% - 40px);
    }
  }
  .form{
    @include PC {margin-top: 20px;}
    table{
      border-collapse: separate;
      @include PC {
        margin-left: -73px;
        border-spacing: 0px 30px;
      }
      @include SP {
        border-spacing: 0px 20px;
      }
    }
    tbody,table{
      width: 100%;
    }
    th{
      font-size: 1.2rem;
      letter-spacing: 0.1em;
      text-align: left;
      font-weight: bold;
      vertical-align: middle;
      @include SP {
        display: block;
        margin-bottom: 10px;
      }
    }
    td{
      text-align: left;
      vertical-align: middle;
      border: solid 1px #000;
      @include PC {
        width: 73%;
        max-width: 450px;
      }
      @include SP {
        width: 100%;
        display: block;
      }
      input,textarea{
        width: 100%;
        font-size: 1.6rem;
        letter-spacing: 0.1em;
        color: #000;
        @include PC {padding: 10px 10px;}
        @include SP {
          padding: 10px;
          line-height: 1.3em;
        }
        &::placeholder {
          color: #ccc;
        }
      }
      textarea{
        height: 190px;
        color: #000;
      }
    }
  }
}
// 同意する
.privacyBox_agree{
  position: relative;
  margin: auto;
  @include PC {
    width: 450px;
  }
}
.privacyBox_txt{
  font-size: 1.2rem;
  text-decoration: underline;
  margin-bottom:1em;
  a{
    &:hover{
      @include PC {
        color: #00789c;
      }
    }
  }
}
.agree{
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 1.2rem;
  color: #444;
  @include PC {
    padding-top: 13px;
    padding-left: 25px;
  }
  @include SP {
    padding-top: 5px;
    padding-left: 15%;
    text-align: left;
  }
  input {
    // position: absolute;
    // z-index: -1;
    // opacity: 0;
  }
}
.agree_indicator {
  position: absolute;
  top: 5px;
  height: 35px; 
  width: 35px;  
  border: 1px solid #000;  
  border-radius: 2px; 
  background-color: #FFFFFF;
  @include PC {
    left: -27px;
  }
  @include SP {
    left: 0px;
  }
}
.agree input:checked ~ 
.agree_indicator:after {
  display: block;
}
.agree{
  @include SP {
    width: 90%;
  }
}
.agree .agree_indicator:after {
  width: 12px;
  height: 20px;
  left: 11px;
  top: 3px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.agree_indicator:after {
    content: '';
    position: absolute;
    display: none;
}
label input:checked + .mwform-checkbox-field-text + .check::after {
  display: block;
  position: absolute;
  width: 16px;
  height: 8px;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(-45deg);
  content: "";
  box-sizing: border-box;
  @include PC {
    left: -16px;
    top: 16px;
  }
  @include SP {
    left: 10px;
    top: 16px;
  }
}
label input:disabled + .mwform-checkbox-field-text + .check:before {
  background-color: #000;
}
label input:checked:disabled + .mwform-checkbox-field-text + .check::after {
  border-left: 3px solid #000;
  border-bottom: 3px solid 000;
}
.mw_wp_form .error {
  position: absolute;
}
.mw_wp_form_preview{
  .form td{
    border: none !important;
    padding-left: 3em;
  }
  .privacyBox_agree{
    display: none;
  }
  .preview_txt{
    display: block;
  }
  .contact_txt,
  .contact_note{
    display: none;
  }
}
.preview_txt{
  display: none;
  font-size: 1.4rem;
}
.mw_wp_form_complete{
  .contact_txt,
  .contact_note{
    display: none;
  }
}
// 確認button
.submitBtn,
.preview_submitBtn{
  font-size: 1.8rem;
  margin: auto;
  margin-top: 35px;
  padding: 15px 0;
  border: solid 1px #000;
  background-color: #fff;
  transition: all .3s 0s ease;
  display: block;
  color: #000;
  @include PC {
    width: 450px;
    cursor: pointer;
  }
  @include SP {
    width: 100%;
  }
  a{
    transition: all .3s 0s ease;
  }
  &:hover{
    background-color: #000;
    color: #fff;
    a{
      color: #fff;
    }
  }
}