#modal{
  transition: all 1s ease-in-out;
  min-height: 100vh;
}
#modal-overlay{
  display:none;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:120%;
  background-color:rgba(0,0,0,.15);
  z-index: 9999;
}
#modal-content{
  display:none;
  position:fixed;
  top:0;
  right:0;
  background-color: #fff;
  z-index: 9999;
  @include PC {
    min-height:100vh;
    width: 100%;
    max-width: 850px;
  }
  @include SP {
    height: 100%;
    width: 85%;
  }
}
#modal-closeBtn{
  position: absolute;
  @include PC {
    width: 30px;
    top: 60px;
    right: 60px;
    cursor: pointer;
  }
  @include SP {
    width: 20px;
    top: 36px;
    right: 30px;
    cursor: pointer;
  }
}
.modal-show{
  @include PC {overflow: hidden;}
}
.modal-iframe{
  width: 100%;
  @include PC {
    height: 100% !important;
  }
}


// modalの中身
.modal-html{
  @include SP {height: auto !important;}
}
.modal-body{
  @include PC {
    width: 730px !important;
    min-width: 730px !important;
  }
  @include SP {
    width: 100% !important;
  }
}
.modal-article{
  overflow-y: scroll;
  @include PC {
    height: 100vh;
    padding: 60px;
  }
  @include SP {
    padding: 30px;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
    -webkit-transform:translateZ(0);
  }
  &__inner{
    font-size: 1.9rem;
  }
  &__title{
    font-weight: bold;
    letter-spacing: 0.1em;

    @include PC {
      font-size: 1.7rem;
      margin-bottom: 60px;
      padding-right: 40px;
    }
    @include SP {
      font-size: 1.6rem;
      margin-bottom: 30px;
      padding-right: 40px;
    }
  }
}
.modal-txt__group{
  margin-bottom: 40px;
}
.modal-txt{
  letter-spacing: 0.01em;
  @include PC {
    font-size: 1.4rem;
    line-height: 1.8em;
    margin-bottom: 30px;
  }
  @include SP {
    font-size: 1.3rem;
    line-height: 1.8em;
    margin-bottom: 20px;
  }
}
.modal-link{
  letter-spacing: 0.01em;
  line-height: 1.8em;
  @include PC {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  @include SP {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  a{
    display: block;
    &:hover{
      @include PC {color: #00789c;}
    }
  }

  dd a{
    // width: 100%;
    display: inline-block;
    word-break: break-all;
  }
}
.modal__list{
  color: #888;
  @include PC {
    font-size: 1.4rem;
    line-height: 2em;
  }
  @include SP {
    font-size: 1.3rem;
    line-height: 1.8em;
  }
}
.modal-dest{
  color: #888;
  display: flex;
  align-items: center;
  @include PC {
    font-size: 1.4rem;
    line-height: 2em;
  }
  @include SP {
    font-size: 1.3rem;
    line-height: 1.8em;
  }
  &__group{
    margin-bottom: 50px;
  }
  &__right{
    display: flex;
    align-items: center;
    a{
      display: block;
      &:hover{
        opacity: .8;
      }
    }
  }
}
.modal-photoBox{
  &__wrap{
    margin-bottom: 30px;
  }
}