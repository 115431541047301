.wrapper{
}
.g-header{
  @include PC {
    width: 100%;
    padding: 28px 0;
    position: fixed;
    z-index: 9999;
    background-color: #fff;
  }
  @include SP {
    width: 100%;
    // height : 100%;
    padding: 25px 0;
    position: fixed;
    z-index: 9999;
    background-color: #fff;
    border-bottom: solid 1px #000;
  }
}
.g-logo{
  @include PC {
    width: 130px;
  }
  @include SP {
    width: 120px;
    margin: auto;
  }
}
.g-wrap{
  @include PC {
    padding: 0 3.33%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.pageslist{
  @include PC {display: flex;}
  @include SP {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
.pageslist_item{
  letter-spacing: 1em;
  @include PC {
    margin-right: 30px;
    font-size: 1.6rem;
  }
  @include SP {
    display: inline-block;
  }
  &.new{
    position: relative;
    &:before{
      content:"";
      width: 6px;
      height: 6px;
      display: block;
      background-color: #00789c;
      border-radius: 50%;
      position: absolute;
      @include PC {
        left: 50%;
        top: -10px;
        transform: translateX(-50%);
      }
      @include SP {
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  a:hover{
    @include PC {
      color: #00789c;
    }
  }
  &:last-child{
    margin-right: 0;
  }
}

// SP　NAV
.g-header .g-nav{
  @include SP {
    display: none;
    width: 100%;
    height: 110%;
    position: fixed;
    top: 98px;
    z-index: 9999;
    background-color: #fff;
    text-align: center;
    border-top: solid 1px #000;
  }
  .pageslist_item{
    @include SP {
      font-size: 2.2rem;
      margin-bottom: 20%;
    }
  }
  .pageslist{
    @include SP {
      position: absolute;
      top: 7%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.g-logo_inner{
  display: flex;
  align-items: end;
}
.g-logo_wrap{
  @include PC {
    display: flex;
    justify-content: flex-end;
  }
  @include SP {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    // position: absolute;
    // left: 20px;
    // top: 15px;
  }
  li{
    &.logo_ias{
      display: none;
      @include PC {
        width: 90px;
        margin-right: 4px;
        margin-left: 30px;
      }
      @include SP {
        width: 60px;
        margin-right: 10px;
      }
      .f-nav &{
        margin-left: 0;
        @include PC {
          width: 100px;
          margin-right: 20px;
        }
      }
    }
    &.logo_gcerti{
      @include PC {
        width: 80px;
      }
      @include SP {
        width: 50px;
        margin-top: -5px;
      }
    }
  }
}
// SP　MENU
.spmenu{
  width: 30px;
  position: absolute;
  right: 20px;
  top: 31px;
  .open,.close{
    position: absolute;
    transition: opacity 200ms linear;
  }
  .open{
    opacity: 1;
  }
  .close{
    opacity: 0;
  }
  &.on{
    .open{
      opacity: 0;
    }
    .close{
      opacity: 1;
    }
  }
}
.pageslist_item-top{
  font-size: 1.6rem;
  margin-bottom: 30px;
}
