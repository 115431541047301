$widthSP: 768px; //スマホsizew
$widthPC: 769px;

@mixin SP {
  @media (max-width: $widthSP) {
    @content;
  }
}

@mixin PC {
  @media (min-width: $widthPC) {
    @content;
  }
}
//color
$pink: #FF0F4B;
$black: #202633;

//easing
$cubic-bezier: cubic-bezier(0.075, 0.82, 0.165, 1);

// $orange: #fe6340;
@mixin middle {
  left: 0;
  right: 0;
  margin: auto;
}
@mixin center {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

@mixin hover {
  @include PC {
    transition: all 300ms 0s ease;
    &:hover{opacity: .8;}
  }
}
@mixin onAni {
  transition: all 1000ms 500ms ease;
}

// @mixin border($color:#666) {
//   border-bottom: 1px solid $color;
// }

// @mixin btnHover ($color_a:#fff, $color_b:#000){
//   font-family: 'Barlow Condensed', sans-serif;
//   text-align: center;
//   box-sizing: content-box;
//   margin: auto;
//   line-height: 1em;
//   // padding-bottom: 2px;
//   @include PC {
//     border: solid 5px $color_a;
//     @include hover
//     &:hover{
//       background-color: $color_a;
//       // border-color: $color_b;
//       a{color: $color_b;}
//     }
//   }
//   @include SP {
//     border: solid 3px $color_a;
//   }
//   a{display: block;}
// }



/* IEハック用mixin */
@mixin hack($IE-ver: null) {
  @if $IE-ver == 'lteIE8' { // IE8以下
    @media \0screen\,screen\9 {
      @content;
    }
  }
  @else if $IE-ver == 'gteIE9' { // IE9以上
    @media screen and (min-width:0\0) {
      @content;
    }
  }
  @else if $IE-ver == 'gteIE10' { // IE10以上
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @content;
    }
  }
  @else if $IE-ver == 'gteIE11' { // IE11以上
    @at-root _:-ms-fullscreen,:root & {
      @content;
    }
  }
  @else if $IE-ver == 'notIE8' { // IE8じゃないとき(他のブラウザも含める)
    @media all and (min-width: 0) {
      @content;
    }
  }
}

 // 使い方
 // @include hack(lteIE8){ /* IE8以下 */
 //    color: red;
 //  }