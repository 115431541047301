#home{
  transition: all 1s ease-in-out;
  min-height: 100vh;
  @include PC {
    background:repeating-linear-gradient(-45deg, #eee 0, #eee 3px, #fff 3px, #fff 6px);
    padding-top: 168px;
  }
  @include SP {padding-top: 160px;}
}
.filter{
  background-color: #f2f2f2;
  position: fixed;
  width: 100%;
  z-index: 9998;
  @include PC {
    padding: 18px 3.33%;
    top: 110px;
  }
  @include SP {
    padding: 20px;
    top: 100px;
  }
  .arrowDown{
    width: 15px;
    height: 14px;
    position: absolute;
    right: 0px;
    top: 5px;
  }
}
.filterWrap{
  display: flex;
  align-items: top;
  @include PC {justify-content: flex-end;}
  @include SP {
    justify-content: left;
    position: relative;
  }
  a:hover{
    @include PC {
      color: #00789c;
    }
  }
}
.filterWrap_tit{
  @include PC {
    margin-right: 25px;
  }
  @include SP {
    font-size: 1.4rem;
    margin-right: 10px;
    margin-top: 1px;
  }
}
.filterWrap_list{
  @include PC {display: flex;}
  @include SP {
    display: none;
    width: calc(100% + 40px);
    position: absolute;
    left: -20px;
    top: 30px;
    padding: 0px 10px 20px 80px;
    background-color: #f2f2f2;
    z-index: 9;
  }
}
.filterWrap_item{
  @include PC {margin-right: 25px;}
  @include SP {margin-bottom: 10px;}
  a{color: #aeaeae;}
  &:last-child{
    margin-right: 0;
  }
  .active{
    color: #000;
    @include SP {
      display: none;
    }
    // text-shadow: 0.3px 0.3px 0 #000; 
  }
}


// boxframe
.article{
  // height: 0px;
  // transition: height 500ms linear;
}
.boxesFrame{
  display: flex;
  flex-wrap: wrap;
}
.boxes{
  // display: none;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  @include PC {
    width: 20%;
    cursor: pointer;
  }
  @include SP {
    width: 100%;
  }
  &.hide{
    display: none;
  }
}
@media only screen and (min-width:426px) and (max-width:768px){
  .boxes {
    width: 50%;
  }
}
@media only screen and (min-width:768px) and (max-width:1024px){
  .boxes {
    width: 25%;
  }
}
@media only screen and (min-width: 1800px){
  .boxes {
    width: 16.66%;
  }
}
@media only screen and (min-width: 2400px){
  .boxes {
    width: 16.66%;
  }
}
.boxesItem{
  display: block;
  @include PC {
    &:hover{
      .thumbnail_tit{
        opacity: 1;
      }
      h2{
        transform: translateX(-50%) translateY(-50%) scale(1);
      }
      .thumbnail{
        opacity: 0;
        transform: scale(1.3);
      }
    }
  }
}
.thumbnail{
  transition: opacity 400ms ease-in-out,transform 400ms ease-in-out;
  transform: scale(1);
  opacity: 1;
}
.thumbnail_tit{
  width: 100%;
  height: 100%;
  // opacity: 1;
  position: absolute;
  top: 0;
  transform: scale(1);
  background-image: radial-gradient(circle,rgba(0,0,0,.4) 0%,rgba(0,0,0,.65) 100%);
  opacity: 0;
  transition: opacity 350ms ease-in-out;
  h2{
    width: 60%;
    font-size: 1.6rem;
    line-height: 26px;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) scale(1.5);
    transition: transform 200ms ease-in-out;
  }
}

//　filterAnimation
// .is-animated {
//   animation: 1s zoom-in forwards;
// }
// @keyframes zoom-in {
//   0% {
//     transform: scale(.8);
//   } 
//   100% {
//     transform: none;
//   }
// }

// VIEW MORE
.viewmore{
  cursor: pointer;
  background-color: #fff;
  position: relative;
  display: none;
}
.viewmore_btn{
  font-size: 1.8rem;
  text-align: center;
  padding: 43px 0;
}